import { HStack, Icon, Text, Tooltip } from '@chakra-ui/react'
import {
  mdiCalendar,
  mdiChevronDown,
  mdiChevronUp,
  mdiCircleSmall,
  mdiFolderOpenOutline,
  mdiFolderOutline,
  mdiFolderRefreshOutline,
  mdiImageOutline,
  mdiLinkVariant,
  mdiLockOutline,
  mdiNumeric,
  mdiPaperclip,
  mdiText,
  mdiToggleSwitchOutline
} from '@mdi/js'
import { JSONPath } from 'jsonpath-plus'
import { DatasourceModel, isObject } from '@sitecore-feaas/sdk'
import type { LeafStatus } from './DatasourceTreeLeafWrapper.js'
import type { ConfigureAction } from './DatasourceTree.js'

const IMAGE_EXTENSIONS = [
  '.jpeg',
  '.jpg',
  '.png',
  '.gif',
  '.bmp',
  '.tif',
  '.tiff',
  '.webp',
  '.webm',
  '.wbmp',
  '.svg',
  '.apng',
  '.ico',
  '.psd',
  '.ai',
  '.eps',
  '.heif',
  '.heic',
  'data:image'
]
const URL_PREFIXES = [
  'http://',
  'https://',
  'ftp://',
  'sftp://',
  'file://',
  'data:',
  'ws://',
  'wss://',
  'ssh://',
  'git://',
  'steam://'
]

const getIcon = (
  type: 'array' | 'empty' | 'object' | 'date' | 'string' | 'image' | 'url' | 'boolean' | 'number',
  action: ConfigureAction | 'reporting' | null
) => {
  const ICONS = {
    date: mdiCalendar,
    array: mdiFolderOpenOutline,
    repeater: mdiFolderRefreshOutline,
    object: mdiFolderOutline,
    image: mdiImageOutline,
    url: mdiLinkVariant,
    number: mdiNumeric,
    string: mdiText,
    boolean: mdiToggleSwitchOutline,
    empty: mdiCircleSmall
  }

  if (type === 'array' && (action === 'repeating-mapping' || action === 'nesting')) return ICONS['repeater']
  else return ICONS[type]
}
const DatasourceTreeLeafContent = ({
  path,
  datasource,
  label,
  collapsedLeaves,
  onCollapsedLeavesChange,
  leafStatus = 'unchanged',
  action
}: {
  path: string
  datasource: DatasourceModel
  label: string
  collapsedLeaves: string[]
  onCollapsedLeavesChange: (path: string[]) => void
  leafStatus?: LeafStatus
  action: ConfigureAction | 'reporting' | null
}) => {
  const value = JSONPath({
    path: path.replaceAll('*', '0').replaceAll('@', '`@'),
    json: datasource.sample,
    wrap: false
  })
  const type =
    value === ''
      ? 'empty'
      : Array.isArray(value)
      ? 'array'
      : isObject(value)
      ? 'object'
      : value instanceof Date
      ? 'date'
      : typeof value === 'string'
      ? IMAGE_EXTENSIONS.some((ext) => value.includes(ext))
        ? 'image'
        : URL_PREFIXES.some((prefix) => value.startsWith(prefix))
        ? 'url'
        : isNaN(+value) && Date.parse(value)
        ? 'date'
        : 'string'
      : typeof value === 'boolean'
      ? 'boolean'
      : !isNaN(value)
      ? 'number'
      : undefined

  const handleCollapseLeaf = (e: React.MouseEvent<SVGElement>) => {
    e.stopPropagation()
    if (collapsedLeaves.includes(path)) onCollapsedLeavesChange(collapsedLeaves.filter((p) => p !== path))
    else onCollapsedLeavesChange([...collapsedLeaves, path])
  }

  return (
    <Tooltip
      placement={'right'}
      hasArrow={false}
      label={type === 'date' ? new Date(value).toISOString() : type !== 'array' && type !== 'object' ? value : null}
    >
      <HStack
        textDecoration={leafStatus === 'removed' ? 'line-through' : 'none'}
        opacity={leafStatus === 'removed' ? 0.5 : 1}
        pl={1}
        pr={1}
      >
        {leafStatus !== 'removed' && (
          <Icon boxSize={'icon-lg'}>
            <path d={getIcon(type, action)} />
          </Icon>
        )}
        <Text fontSize={'13px'}>{path === '$' || path === '$.*' ? datasource.name : label}</Text>
        {((type === 'object' && Object.keys(value).length) || (type === 'array' && value.length)) && (
          <Icon cursor={'pointer'} boxSize={'icon-xl'} onClick={(e) => handleCollapseLeaf(e)}>
            <path d={collapsedLeaves.includes(path) ? mdiChevronUp : mdiChevronDown} />
          </Icon>
        )}
      </HStack>
    </Tooltip>
  )
}

export default DatasourceTreeLeafContent
